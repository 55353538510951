<template>
  <div class="satisfaction-mine-view">
    <tojoy-title-content title="我的满意度">
      <template #footer>
        <div v-if="!inviterPersonNums" class="inviter-tip">您还没邀请评价人哦</div>
        <div class="tojoy-title-content__footer">
          <tojoy-tabs v-model="tabName" :tabs="tabs" @tab-click="handleTabClick" />
          <el-button size="medium" @click="$router.push({ name: 'satisfaction-inviter' })">
            <span class="icon iconfont iconpeople"></span>
            {{ inviterPersonBtn }}
          </el-button>
        </div>
      </template>
    </tojoy-title-content>
    <tojoy-pagination-list
      v-if="tabName === 'received'"
      key="satisfaction-received"
      :loading="loading"
      :total="receivedData.total"
      :data="receivedData.list"
      @page-change="val => handleChangePage(val, 'received')"
    >
      <template #item="{data}">
        <tojoy-card
          :data="{
            time: String(data.evaluateDay).slice(4, 6),
            unit: String(data.evaluateDay).slice(0, 4)
          }"
        >
          <template #rightcard>
            <received-item
              v-for="item in data.outputs"
              :key="item.id"
              :data="item"
              @click-user-card="
                u => $router.push({ name: 'homepage-navigation', query: { userId: u.userid } })
              "
              @refresh-list="handleRefreshList"
            />
          </template>
        </tojoy-card>
      </template>
    </tojoy-pagination-list>
    <section class="tab-comment" v-show="tabName === 'comment'">
      <template v-if="commentData.outputs && commentData.outputs.length > 0">
        <div class="tab-comment__main">
          <div class="tab-comment__main-scroll">
            <comment-item
              v-for="item in commentData.outputs"
              :key="item.id"
              :data="item"
              @complete-rate="() => hadRateNum++"
              @click-user-card="
                u => $router.push({ name: 'homepage-navigation', query: { userId: u.userid } })
              "
            />
          </div>
        </div>
        <div class="tab-comment__footer">
          <p>你的评价将匿名呈现，请放心评价</p>
          <el-button type="primary" :loading="submitLoading" @click="handleSubmitComment">{{
            submitBtnText
          }}</el-button>
        </div>
      </template>
      <tojoy-blank v-else :blank-data="{ type: 'notdata' }" />
    </section>
    <tojoy-pagination-list
      v-if="tabName === 'complete'"
      key="satisfaction-complete"
      :loading="loading"
      :total="completeData.total"
      :data="completeData.list"
      @page-change="val => handleChangePage(val, 'complete')"
    >
      <template #item="{data}">
        <tojoy-card
          :data="{
            time: String(data.evaluateDay).slice(4, 6),
            unit: String(data.evaluateDay).slice(0, 4)
          }"
        >
          <template #rightcard>
            <complete-item
              v-for="item in data.listOutputs"
              :key="item.id"
              :data="item"
              @click-user-card="
                u => $router.push({ name: 'homepage-navigation', query: { userId: u.userid } })
              "
            />
          </template>
        </tojoy-card>
      </template>
    </tojoy-pagination-list>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import TojoyTitleContent from '@/components/common/initiate-team/title-content'
import TojoyCard from '@/components/business/card'
import TojoyPaginationList from '@/components/business/pagination-list'
import TojoyBlank from '@/components/business/blank/blank'
import TojoyTabs from '@/components/business/tabs'
import ReceivedItem from '@/components/satisfaction/received-item'
import CommentItem from '@/components/satisfaction/comment-item'
import CompleteItem from '@/components/satisfaction/complete-item'
import tabsRouteMixins from '@/mixins/tabsRouteMixins'
import watchBroswerBackMixins from '@/mixins/satisfaction/watchBroswerBack'
import { handleDataBF, BF_TYPE } from './handle_data'
import {
  mineReceive,
  mineAppeal,
  mineEvaluateQuery,
  mineEvaluateQueryDetail,
  mineEvaluateSave,
  mineInviteQuery
} from '@/api/api_satisfaction'

export default {
  name: 'satisfaction-mine',
  mixins: [tabsRouteMixins, watchBroswerBackMixins],
  components: {
    TojoyPaginationList,
    TojoyCard,
    TojoyTitleContent,
    ReceivedItem,
    CommentItem,
    CompleteItem,
    TojoyBlank,
    TojoyTabs
  },
  props: {
    moduleName: {
      type: String,
      default: 'received'
    }
  },
  data() {
    return {
      receivedData: [],
      commentData: [],
      completeData: [],
      inviterPerson: [],
      tabName: this.moduleName,
      currentPage: 1, // 标识当前页码，刷新用
      hadRateNum: 0,
      pagination: {
        page: 1,
        size: 10
      },
      submitLoading: false, // 待我评价-表单防重
      loading: false
    }
  },
  computed: {
    ...mapState({
      tabs: state => state.satisfaction.mineTabs
    }),
    submitBtnText() {
      return `批量提交评价(已评${this.hadRateNum ?? 0}人/${this.commentData.outputs?.length ?? 0})`
    },
    inviterPersonNums() {
      return this.inviterPerson?.outputs?.length ?? 0
    },
    inviterPersonBtn() {
      return `邀请评价人(${this.inviterPersonNums})`
    }
  },
  async created() {
    this.loading = true
    this.handleTabClick({ name: this.tabName })
    this.loadInvitePersonList()
    this.loadCommentData()
  },
  methods: {
    ...mapActions('satisfaction', ['loadMineRed']),
    /**
     * 加载我已收到的列表数据
     */
    async loadReceiveData(params, options = {}) {
      this.loading = true
      const { code, data } = await mineReceive({ ...params }, options)
      if (code === '000000') {
        this.receivedData = handleDataBF(data, BF_TYPE.teamList)
        this.loading = false
      }
    },
    /**
     * 加载待我评价列表数据（无分页)
     */
    async loadCommentData() {
      const { code, data } = await mineEvaluateQuery()
      if (code === '000000') {
        this.commentData = handleDataBF(data, BF_TYPE.commontList)
      }
    },
    /**
     * 加载我已评的列表数据
     */
    async loadCompleteData(params, options = {}) {
      this.loading = true
      const { code, data } = await mineEvaluateQueryDetail({ ...params }, options)
      if (code === '000000') {
        this.completeData = handleDataBF(data, BF_TYPE.completeList)
        this.loading = false
      }
    },
    /**
     * 批量提交
     */
    async handleSubmitComment() {
      const _needcomment = this.commentData.outputs
        .filter(item => !item.hadrate)
        .map(item => item.username)
      if (_needcomment.length) {
        this.$warning(`请完成所有成员评价后再提交`)
        // ElMessage.warning({
        //   message: `${_needcomment.join(',')}还未评价`,
        //   type: 'warning'
        // })
      } else {
        this.submitLoading = true
        const _comment = handleDataBF(this.commentData, BF_TYPE.commontSubmit)
        const { code } = await mineEvaluateSave(this.$filterParamsTrim(_comment))
        if (code === '000000') {
          this.$msgSuccess(`评价成功 共评价${this.hadRateNum}人`)
          await this.loadCommentData()
          await this.loadMineRed()
          this.hadRateNum = 0
        }
        this.submitLoading = false
      }
    },
    /**
     * 加载邀请评价人列表
     */
    async loadInvitePersonList() {
      const { code, data } = await mineInviteQuery()
      if (code === '000000') {
        this.inviterPerson = data
      }
    },
    handleTabClick({ name }) {
      this.currentPage = 1
      switch (name) {
        case 'received':
          this.loadReceiveData({ ...this.pagination })
          break
        case 'comment':
          // this.loadCommentData()
          break
        case 'complete':
          this.loadCompleteData({ ...this.pagination })
          break
      }
    },
    handleChangePage(val, type) {
      this.currentPage = val
      switch (type) {
        case 'received':
          this.loadReceiveData({ page: val, size: this.pagination.size }, { loading: true })
          break
        case 'complete':
          this.loadCompleteData({ page: val, size: this.pagination.size }, { loading: true })
          break
      }
    },
    handleRefreshList(type) {
      this.handleChangePage(this.currentPage, type)
    }
  }
}
</script>

<style lang="scss">
.satisfaction-mine-view {
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  .tab-comment {
    height: 100%;
    overflow: auto;
    flex: 1;
    display: flex;
    flex-direction: column;
    &__main {
      flex: 1;
      padding: 20px 30px;
      // margin-bottom: 20px;
      // background: $white;
      overflow: auto;
      &-scroll {
        background: $white;
        border-radius: 5px;
      }
    }
    &__footer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      background: $white;
      height: 64px;
      padding: 0 30px;
      z-index: 1;
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
      p {
        font-size: 12px;
        font-weight: 400;
        color: $yellow;
        line-height: 12px;
        margin-right: 20px;
      }
      .el-button {
        height: 44px;
      }
    }
  }
  .tojoy-title-content__footer {
    min-height: 50px;
    .iconpeople {
      font-size: 13px;
    }
    .el-button {
      margin-bottom: 2px;
      position: absolute;
      right: 30px;
    }
  }
  .inviter-tip {
    animation-name: inviterTip;
    animation-duration: 12s;
    animation-timing-function: ease-in-out;
    animation-delay: 0.8s;
    position: absolute;
    right: 30px;
    top: 50px;
    font-size: 13px;
    font-weight: 400;
    color: $white;
    line-height: 13px;
    background: $black;
    border-radius: 3px;
    opacity: 0;
    padding: 9px 14px 8px;
    &::after {
      content: '';
      position: absolute;
      display: block;
      width: 0;
      height: 0;
      border: 10px solid transparent;
      bottom: -17px;
      left: 45%;
      border-top-color: $black;
    }
  }
  .tojoy-pagination-list {
    flex: 1;
  }
}

@keyframes inviterTip {
  0% {
    top: 50px;
    opacity: 0;
  }
  10% {
    top: 10px;
    opacity: 0.5;
  }
  25% {
    top: 10px;
    opacity: 0.5;
  }
  50% {
    top: 10px;
    opacity: 0.5;
  }
  75% {
    top: 10px;
    opacity: 0.5;
  }
  90% {
    top: 10px;
    opacity: 0.5;
  }
  96% {
    opacity: 0.5;
  }
  100% {
    top: 50px;
    opacity: 0;
  }
}
</style>
