<script lang="jsx">
import TojoyRate from '@/components/common/rate'
import TojoyFile from '@/components/common/file'
import SmallUserCard from '@/components/democracy/small-user-card.vue'
import RateHtml from './rate-html.jsx'
import ComplaintHtml from './complaint-html.jsx'
import TouchHtml from './touch-html.jsx'
import '@/components/democracy/edit-dialog.scss'
import './item.scss'
import { mineAppeal } from '@/api/api_satisfaction'
export default {
  name: 'satisfaction-received-item',
  components: {
    TojoyRate,
    TojoyFile,
    SmallUserCard,
    RateHtml,
    ComplaintHtml,
    TouchHtml
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      appealShow: false,
      appealForm: {
        appealInput: '',
      },
      currentAppealData: {}, // 申诉
    }
  },
  methods: {
    handleAppealShow(data) {
      this.currentAppealData = data
      this.appealShow = !this.appealShow
      if(this.appealShow) {
        this.$nextTick(() => {
          this.$refs['appealForm'].resetFields();
          this.$refs['appealInput'].focus()
        })
      }
    },
    handleDialogClose() {
      this.appealForm.appealInput = ''
    },
    handleAppealSubmit() {
      this.$refs['appealForm'].validate(async (valid) => {
        if (valid) {
          // TODO 提交申诉逻辑
          const { code, data } = await mineAppeal({
            id: this.currentAppealData.id,
            content: this.appealForm.appealInput
          })
          this.appealShow = !this.appealShow
          this.appealForm.appealInput = ''
          if(code === '000000') {
            this.$msgSuccess('申诉成功')
          }
          this.$emit('refresh-list', 'received')
        } else {
          return false;
        }
      });
    },
    /**
     * 申诉-弹框
     */
    appealDialog() {
      const attrs = {model: this.appealForm}
      return (
        <el-dialog
          title="申诉"
          append-to-body
          custom-class="appeal-dialog"
          center
          visible={this.appealShow}
          {...{on:{'update:visible': val => this.appealShow = val}}}
          width="560px"
          on-close={() => this.handleDialogClose()}>
          <el-form
            {...{attrs}}
            ref="appealForm">
            <el-form-item
              prop="appealInput"
              rules={[
                { required: true, message: '申诉内容不能为空', trigger: 'blur'},
                { validator: this.$checkTrim, message: '申诉内容不能为空'},
              ]}>
              <el-input
                ref="appealInput"
                type="textarea"
                v-filteremoji
                rows={6}
                resize="none"
                placeholder="请填写申诉内容(必填)"
                maxlength="200"
                show-word-limit
                v-model={this.appealForm.appealInput}
              />
            </el-form-item>
          </el-form>
          <template slot="footer">
            <el-button type="primary" size="small" onClick={() => this.handleAppealSubmit()}>确 定</el-button>
          </template>
        </el-dialog>
      )
    },
  },

  render(h) {
    const {data} = this
    return (
      <div class="satisfaction-received-item">
        <small-user-card
          size={38}
          data={{avatar: data.avatar, userid: data.userid, username: data.userName, position: data.job}}
          on-user-card={u => this.$emit('click-user-card', u)}
        >
          <template slot="right">{data.state ? `${data.score ?? 0}分` : '评价中'}</template>
        </small-user-card>
        { /** 内部满意度 TODO加v-show **/ }
        <rate-html data={data} isOuter={true} />
        { /** 外部满意度 TODO加v-show **/  }
        <rate-html data={data} isOuter={false} />
        { /** 客户投诉 TODO加v-show */ }
        <complaint-html data={data} isMine={true} />
        { /** 客户感动 TODO加v-show */}
        <touch-html data={data} isMine={false} />
        { /** 客户申诉 */ this.appealDialog()}
      </div>
    )
  },
}
</script>
